import { template as template_3f037656065b407b8c62861ccae5cacc } from "@ember/template-compiler";
const FKText = template_3f037656065b407b8c62861ccae5cacc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
