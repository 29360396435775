import { template as template_8554d03f1a9a45deb0c070da8b2aaf20 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8554d03f1a9a45deb0c070da8b2aaf20(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
