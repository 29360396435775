import { template as template_3b92756775394a58bc82b2c9dccae677 } from "@ember/template-compiler";
const FKLabel = template_3b92756775394a58bc82b2c9dccae677(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
