import { template as template_fec9d8cff18d4900adf809d43d3daeb3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fec9d8cff18d4900adf809d43d3daeb3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
